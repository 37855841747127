import React from 'react';
import { Helmet } from "react-helmet"
import { Layout } from "../components/Layout"

const styles = {
    fontContact: {
        color: "#fff",
        fontFamily: "DM Sans",
    },
    alignContent: {
        display: "flex",
        alignItems: "center",
    },
}

const myHTML = `
  <script>
  window.intercomSettings = {
    api_base: "https://api-iam.intercom.io",
    app_id: "r0okszr3"
  };
</script>

<script>
// We pre-filled your app ID in the widget URL: 'https://widget.intercom.io/widget/r0okszr3'
(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/r0okszr3';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
</script>
`;

export default function IntercomDemo() {
    return (
        <div>
            <Helmet htmlAttributes={{ lang: 'en' }}>
                <meta name={`robots`} content={`noindex, nofollow`} />
                <link
                    rel="canonical"
                    href="https://irisagent.com/intercom-demo/"
                />
            </Helmet>
        <div dangerouslySetInnerHTML={{ __html: myHTML }} />
            <section
                className="inner-page"
                style={{
                    paddingTop: "80px",
                }}
            >
                {/*  ======= Contact Section =======  */}

                <section id="contact" className="contact">
                    <div className="imageFilter"></div>
                    <div className="info-container" style={styles.fontContact}>

                        <h1 className="title-web" style={styles.fontContact}>
                            This is the Chatbot playground
                        </h1>
                    </div>
                    <img
                        className="imageMovil"
                        src="/img/contactImage.svg"
                        alt=""
                        width={360}
                    />
                </section>
                {/*  End Contact Section  */}
            </section>
        </div>
    )
}
